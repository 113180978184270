import { Theme } from 'theme-ui';

// const colors: Theme['colors'] = {
const colors = {
  text: '#1B202E',
  background: '#FFF',
  primary: '#3C7AB5',
  secondary: '#86878C',
  accent: '#2B40B5',
  highlight: '#E4E6EC',
  muted: '#D8D8D8',
  white: '#ffffff',
  elevated: '#ffffff',
  sheet: '#f9fafc',
  sunken: '#e0e6ed',
  gray0: '#9AA0B5',
  gray1: '#E4E6EC',
  gray2: '#EEEFF3',
  gray3: '#F6F6F9',
  error: '#E03B54',
  success: '#00CD98',
};

export default colors;
