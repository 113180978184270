import { Theme } from "theme-ui";
import buttons from "./theme/buttons";
import cards from "./theme/cards";
import colors from "./theme/colors";
import forms from "./theme/forms";
import text from "./theme/text";

const theme = {
  breakpoints: ["32em", "48em", "64em", "96em", "128em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  // sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048],
  sizes: {
    widePlus: 2048,
    wide: 1536,
    layoutPlus: 1200,
    layout: 1024,
    copyUltra: 980,
    copyPlus: 768,
    copy: 680,
    narrowPlus: 600,
    narrow: 512,
  },
  //   Font type yang digunakan: Manrope
  // Headline / Big Title, weight strong (bold), 40px
  // Subtitle, weight strong (bold), 20px
  // Body, weight regular, 14-16px
  fonts: {
    // heading:
    //   '"Phantom Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
    body: '"Manrope", sans-serif',
    // monospace: '"SF Mono", "Roboto Mono", Menlo, Consolas, monospace',
  },
  // fontSizes: [12, 16, 20, 24, 32, 48, 64, 96, 128, 160, 192],
  fontSizes: [14, 16, 20, 40],
  fontWeights: {
    regular: 400,
    strong: 700,
    heading: 700,
  },
  layout: {
    container: {
      maxWidth: ["layout", null, "layoutPlus"],
      width: "100%",
      mx: "auto",
      px: 3,
    },
    wide: {
      variant: "layout.container",
      maxWidth: ["layout", null, "wide"],
    },
    copy: {
      variant: "layout.container",
      maxWidth: ["copy", null, "copyPlus"],
    },
    narrow: {
      variant: "layout.container",
      maxWidth: ["narrow", null, "narrowPlus"],
    },
  },
  lineHeights: {
    limit: 0.875,
    title: 1,
    heading: 1.125,
    subheading: 1.25,
    caption: 1.375,
    body: 1.5,
  },
  // text: {
  //   heading: {
  //     fontFamily: "heading",
  //     lineHeight: "heading",
  //     fontWeight: "heading",
  //   },
  // },
  letterSpacings: {
    title: "-0.009em",
    headline: "0.009em",
  },
  radii: {
    small: 4,
    default: 8,
    extra: 12,
    ultra: 16,
    circle: 99999,
  },
  shadows: {
    text: "0 1px 2px rgba(0, 0, 0, 0.25), 0 2px 4px rgba(0, 0, 0, 0.125)",
    small: "0 1px 2px rgba(0, 0, 0, 0.0625), 0 2px 4px rgba(0, 0, 0, 0.0625)",
    card: "0 4px 8px rgba(0, 0, 0, 0.125)",
    elevated:
      "0 1px 2px rgba(0, 0, 0, 0.0625), 0 8px 12px rgba(0, 0, 0, 0.125)",
    inset: "inset 0 1px 0, 0 1px hsl(224, 84%, 74%) 3px hsla(0, 0%, 0%, .2)",
  },
  alerts: {
    error: {
      color: '#9e101b',
      backgroundColor: '#f9edef',
      border: '1px solid',
      borderLeft: '3px solid',
      borderColor: '#df1627'
    }
  },
  buttons,
  cards,
  colors,
  forms,
  text,
  styles: {
    links: {
      shareLink: {
        mb: 2,
        pb: 2,
        borderBottom: '1px solid',
        borderColor: '#d5d0d0',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'unset'
      }
    },
    box: {
      shareLink: {
        mb: 2,
        pb: 2,
        borderBottom: '1px solid',
        borderColor: '#d5d0d0',
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'unset'
      },
      timeBox: {
        border: '1px solid #D1D5DB',
        borderRadius: 8,
        padding: 1,
        textAlign: 'center',
        boxShadow: 'text'
      }
    },
    root: {
      fontFamily: "body",
    },
    table: {
      borderRadius: "default",
      borderCollapse: "collapse",
      width: "100%",
      mb: "4",
      boxShadow: 'card',
    },
    analitycTable: {
      borderRadius: "default",
      borderCollapse: "collapse",
      width: "100%",
      mb: "4",
    },
    thead: {
      borderBottom: "0.5px solid",
    },
    th: {
      p: '6px 16px',
      textAlign: "left",
      fontWeight: "bold",
    },
    tr: {
      borderTop: "1px solid",
      borderTopColor: "highlight",
    },
    td: {
      p: 3,
    },
  },
  // } as Theme;
};

export default theme;
