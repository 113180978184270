import { Theme } from "theme-ui";

// const buttons: Theme['buttons'] = {
const buttons = {
  btnLink: {
    p: 0,
    fontFamily: "body",
    borderRadius: "10px",
    outline: "none",
    cursor: "pointer",
    bg: "transparent",
    border: "1px solid",
    borderColor: "primary",
    color: "primary",
    fontWeight: "normal",
    width: "100%",
    maxWidth: "25rem",
    zIndex: 0,
    height: "50px",
  },
  error: {
    backgroundColor: 'error',
    borderRadius: '12px',
    cursor: 'pointer',
    ":focus,:hover": {
      bg: "#F4F1DD",
      color: "#B9A511",
    },
  },
  socialBtn: {
    p: "5px 0px 0px 0px",
    fontFamily: "body",
    borderRadius: "50%",
    outline: "none",
    cursor: "pointer",
    bg: "transparent",
    border: "1px solid",
    borderColor: "primary",
    color: "primary",
    fontWeight: "normal",
    height: "60px",
    width: "60px",
  },
  // primary: {
  //   py: 2,
  //   px: 3,
  //   fontFamily: "body",
  //   borderRadius: "s",
  //   fontSize: "15px",
  //   outline: "none",
  //   cursor: "pointer",
  //   transition: "background-color 0.3s",
  //   "&:disabled,&[disabled]": {
  //     cursor: "not-allowed",
  //     bg: "highlight",
  //     color: "muted",
  //   },
  // },
  outline: {
    py: "8px",
    px: 3,
    fontFamily: "body",
    borderRadius: "s",
    outline: "none",
    cursor: "pointer",
    bg: "transparent",
    border: "1px solid",
    borderColor: "primary",
    color: "primary",
    fontWeight: "normal",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      border: "1px solid",
      borderColor: "highlight",
      color: "muted",
    },
  },
  iconBtn: {
    p: "5px 0px 0px 0px",
    fontFamily: "body",
    borderRadius: "m",
    outline: "none",
    cursor: "pointer",
    bg: "transparent",
    border: "1px solid",
    borderColor: "primary",
    color: "primary",
    fontWeight: "normal",
    height: "45px",
    width: "45px",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      border: "1px solid",
      borderColor: "highlight",
      color: "muted",
    },
  },
  forPromo: {
    cursor: "pointer",
    border: "1px solid #fff",
    borderRadius: "m",
    padding: "0 8px",
    width: "60px",
    height: "24px",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "white",
    bg: "unset",
  },
  primary: {
    bg: "#332f11",
    cursor: "pointer",
    fontFamily: "inherit",
    fontWeight: "bold",
    borderRadius: "extra",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "inset",
    letterSpacing: "headline",
    WebkitTapHighlightColor: "transparent",
    transition: "background-color .250s ease-in-out, color .250s ease-in-out",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      bg: "highlight",
      color: "muted",
    },
    ":focus,:hover": {
      // boxShadow: "elevated",
      // transform: "scale(1.025)",
      bg: "#F4F1DD",
      color: "#B9A511",
    },
    svg: {
      ml: -1,
      mr: 2,
    },
  },
  menu: {
    bg: "sheet", //#F6F6F6
    color: "#333333",
    cursor: "pointer",
    fontFamily: "inherit",
    fontWeight: "bold",
    borderRadius: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "inset",
    letterSpacing: "headline",
    WebkitTapHighlightColor: "transparent",
    transition: "background-color .250s ease-in-out, color .250s ease-in-out",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      bg: "highlight",
      color: "muted",
    },
    ":focus,:hover": {
      // boxShadow: "elevated",
      // transform: "scale(1.025)",
      bg: "#F4F1DD",
      color: "#B9A511",
    },
    svg: {
      ml: -1,
      mr: 2,
    },
  },
  menuActive: {
    variant: "buttons.menu",
    bg: "#F4F1DD",
    color: "#B9A511",
    cursor: "pointer",
    fontFamily: "inherit",
    borderRightWidth: 2,
    borderRightStyle: "solid",
    borderRightColor: "#333333",
    fontWeight: "bold",
    borderRadius: 0,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "inset",
    letterSpacing: "headline",
    WebkitTapHighlightColor: "transparent",
    transition: "background-color .250s ease-in-out, color .250s ease-in-out",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      bg: "highlight",
      color: "muted",
    },
    ":focus,:hover": {},
    svg: {
      ml: -1,
      mr: 2,
    },
  },
  lg: {
    variant: "buttons.primary",
    fontSize: 3,
    lineHeight: "title",
    px: 4,
    py: 3,
  },
  outline: {
    variant: "buttons.primary",
    bg: "transparent",
    color: "#332f11",
    border: "2px solid currentColor",
  },
  outlineLg: {
    variant: "buttons.primary",
    bg: "transparent",
    color: "primary",
    border: "2px solid currentColor",
    lineHeight: "title",
    fontSize: 3,
    px: 4,
    py: 3,
  },
  cta: {
    variant: "buttons.primary",
    fontSize: 2,
  },
  ctaLg: {
    variant: "buttons.primary",
    lineHeight: "title",
    fontSize: 3,
    px: 4,
    py: 3,
  },
  transparent: {
    bg: "transparent",
    color: "#333333",
    cursor: "pointer",
    fontFamily: "inherit",
    fontWeight: "bold",
    // borderRadius: "extra",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    // boxShadow: "inset",
    letterSpacing: "headline",
    WebkitTapHighlightColor: "transparent",
    // transition: "background-color .250s ease-in-out, color .250s ease-in-out",
    "&:disabled,&[disabled]": {
      cursor: "not-allowed",
      bg: "highlight",
      color: "muted",
    },
  },
  icon: {
    cursor: "pointer",
  },
};

export default buttons;
