import { Theme } from "theme-ui";

// const cards: Theme['cards'] = {
const cards = {
  mini: {
    bg: "elevated",
    color: "text",
    p: [1, 2],
    borderRadius: "extra",
    boxShadow: "card",
    overflow: "hidden",
  },
  primary: {
    bg: "elevated",
    color: "text",
    p: [3, 4],
    borderRadius: "extra",
    boxShadow: "card",
    overflow: "hidden",
  },
  full: {
    bg: "elevated",
    color: "text",
    borderRadius: "extra",
    boxShadow: "card",
    overflow: "hidden",
    height: "100%",
    overflow: "hidden",
  },
  sunken: {
    bg: "sunken",
    p: [2],
    borderRadius: "extra",
  },
  time: {
    bg: "elevated",
    color: "text",
    p: [3, 4],
    borderRadius: "extra",
    // boxShadow: "card",
    border: "1px solid #D1D5DB",
    overflow: "hidden",
  },
  tooltip: {
    border: "1px solid #EDEDED",
    backgroundColor: "secondary",
    color: "white",
    borderRadius: '14px',
    minHeight: '50px',
    maxWidth: '270px',
    padding: '18px',
    opacity: '0.9',
    textAlign: 'center'
  },
  activity: {
    border: "1px solid #EDEDED",
    borderRadius: '14px',
    minHeight: '100px',
    minWidth: '200px',
    padding: '18px 20px'
  },
  selectedActivity: {
    border: '2px solid #B9A511',
    borderRadius: '14px',
    minHeight: '100px',
    minWidth: '200px',
    padding: '18px 20px'
  },
  sheet: {
    bg: "sheet",
    p: [2],
    borderRadius: "extra",
  },
  interactive: {
    variant: "cards.primary",
    textDecoration: "none",
    WebkitTapHighlightColor: "transparent",
    transition: "transform .125s ease-in-out, box-shadow .125s ease-in-out",
    ":hover,:focus": {
      transform: "scale(1.0625)",
      boxShadow: "elevated",
    },
  },
  translucent: {
    backgroundColor: "rgba(255, 255, 255, 0.98)",
    color: "text",
    boxShadow: "none",
    "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)": {
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      backdropFilter: "saturate(180%) blur(20px)",
      WebkitBackdropFilter: "saturate(180%) blur(20px)",
    },
    "@media (prefers-reduced-transparency: reduce)": {
      backdropFilter: "none",
      WebkitBackdropFilter: "none",
    },
  },
  translucentDark: {
    backgroundColor: "rgba(0, 0, 0, 0.875)",
    color: "white",
    boxShadow: "none",
    "@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none)": {
      backgroundColor: "rgba(0, 0, 0, 0.625)",
      backdropFilter: "saturate(180%) blur(16px)",
      WebkitBackdropFilter: "saturate(180%) blur(16px)",
    },
    "@media (prefers-reduced-transparency: reduce)": {
      backdropFilter: "none",
      WebkitBackdropFilter: "none",
    },
  },
};

export default cards;
