const sharedInputStyles = {
  fontFamily: "body",
  background: "white",
  borderRadius: "extra",
  border: "1px solid",
  borderColor: "gray1",
  p: 2,
  // "&:focus,&:not(:placeholder-shown)": {
  //   outline: "none",
  //   borderColor: "gray0",
  // },
  "::placeholder,:-ms-input-placeholder,::-ms-input-placeholder": {
    color: "gray0",
  },
  "&:disabled,&[disabled]": {
    bg: "highlight",
    color: "muted",
  },
};

const forms = {
  // checkbox: {
  //   "input:checked ~ &": { color: "secondary" },
  //   "input:disabled ~ &": {
  //     color: "gray1",
  //     bg: "gray2",
  //   },
  // },
  // radio: {
  //   "input:checked ~ &": { color: "secondary" },
  //   "input:disabled ~ &": {
  //     color: "gray1",
  //     bg: "gray2",
  //   },
  // },
  // select: {
  //   ...sharedInputStyles,
  //   "& + svg": {
  //     ml: "-3.125rem",
  //     // display: 'none',
  //   },
  // },
  input: {
    ...sharedInputStyles,
  },
  // textarea: {
  //   ...sharedInputStyles,
  // },
  error: {
    ...sharedInputStyles,
    borderColor: "error",
    // borderColor: "error",
    // "&:focus,&:not(:placeholder-shown)": {
    //   outline: "none",
    //   borderColor: "error",
    // },
  },
  search: {
    ...sharedInputStyles,
    width: "35%",
    marginBottom: 3
  },
  password: {
    ...sharedInputStyles,
    paddingRight: 4,
  },
  select: {
    ...sharedInputStyles,
    borderRadius: 'default',
    height: '40px',
    width: '148px',
  }
};

export default forms;
