const text = {
  heading: {
    fontWeight: "strong",
    lineHeight: "heading",
    mt: 0,
    mb: 0,
  },
  ultratitle: {
    fontSize: [5, 6, 7],
    lineHeight: "limit",
    fontWeight: "strong",
    letterSpacing: "title",
  },
  title: {
    fontSize: [4, 5, 6],
    fontWeight: "strong",
    letterSpacing: "title",
    lineHeight: "title",
  },
  subtitle: {
    // mt: 3,
    // fontSize: [2, 3],
    // fontWeight: "regular",
    // letterSpacing: "headline",
    // lineHeight: "subheading",
    fontSize: 2,
    fontWeight: "strong",
  },
  headline: {
    // variant: "text.heading",
    // letterSpacing: "headline",
    // lineHeight: "heading",
    // fontSize: 4,
    // mt: 3,
    // mb: 3,
    fontSize: 3,
    fontWeight: "strong",
  },
  body: {
    fontSize: 1,
    fontWeight: "regular",
  },
  logout: {
    fontSize: 1,
    fontWeight: "regular",
    cursor: 'pointer',
    mb: 1,
    p: 1,
    ":focus,:hover": {
      p: 1,
      borderRadius: 4,
      bg: "#F4F1DD",
      color: "#B9A511",
    },
  },
  subheadline: {
    variant: "text.heading",
    letterSpacing: "headline",
    fontSize: 2,
    mt: 0,
    mb: 3,
  },
  eyebrow: {
    color: "muted",
    fontSize: [3, 4],
    fontWeight: "heading",
    letterSpacing: "headline",
    lineHeight: "subheading",
    textTransform: "uppercase",
    mt: 0,
    mb: 2,
  },
  lead: {
    fontSize: [1, 2],
    my: [2, 3],
  },
  caption: {
    fontSize: 0,
    color: "muted",
    fontWeight: "medium",
    letterSpacing: "headline",
    lineHeight: "caption",
  },
};

export default text;
